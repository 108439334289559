// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { Box, Center, Flex, Heading, Link, Stack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { MdOutlineBookmarkBorder } from 'react-icons/md';
import { Fade } from '~/components/Fade';
import { ReactIcon } from '~/components/Icons';
import { Footer } from '~/containers/layouts/marketing/Footer';
import { Header } from '~/containers/layouts/marketing/Header';
import { BetaCtaRow } from '~/containers/pages/LandingPage/BetaCtaRow';
import { InfoBanner } from '~/containers/pages/LandingPage/InfoBanner';
import { Section } from '~/containers/pages/LandingPage/Section';
import { check_webp_feature } from '~/lib/webp';
import { CTA } from './CTAButton';
import { LandingStack } from './LandingStack';
import { MobileButtons } from './MobileButtons';
import { Reviews } from './Reviews';

export const LandingPage: FC = () => {
  const router = useRouter();

  const handleFooterButtonClick = () => {
    router.push('/signup');
  };

  const [bgImages, setbgImages] = useState([
    'url(/landing/hero-phone-stack-left.webp)',
    '/**/url(/landing/hero-phone-stack-left.webp)',
    '/**/url(/landing/hero-phone-stack-left.webp)',
  ]);
  const [bgImagesRight, setbgImagesRight] = useState([
    'url(/landing/hero-phone-stack-right-mobile.webp)',
    '/**/url(/landing/hero-phone-stack-right-mobile.webp)',
    '/**/url(/landing/hero-phone-stack-right-mobile.webp)',
    '/**/url(/landing/hero-phone-stack-right.webp)',
  ]);

  useEffect(() => {
    check_webp_feature('alpha').catch(() => {
      // When webp is not supported switch back to using png
      setbgImages((images) => images.map((i) => i.replaceAll('.webp', '.png')));
      setbgImagesRight((images) =>
        images.map((i) => i.replaceAll('.webp', '.png')),
      );
    });
  }, []);

  return (
    <>
      <Header />
      {/** Hero add leading comment because chakra converts url to url-graident */}
      <Flex
        minH={['30em', '40em']}
        overflow="hidden"
        className="hero"
        justifyContent="center"
        pos={'relative'}
        zIndex={2}
      >
        <Flex
          mt={0}
          maxW="5xl"
          justifyContent={['start', 'start', 'center']}
          alignItems="center"
          flex="auto"
          px={[4, 4, 0]}
          zIndex={5}
          pos={'relative'}
        >
          <Stack
            maxW={['2xs', '2xl']}
            justifyContent="end"
            flexDirection="column"
            textAlign={['left', 'left', 'center']}
            px={[0, '5em', 0]}
            gap={10}
          >
            <Heading lineHeight="shorter" fontSize={['4xl', '4xl', '6xl']}>
              All your bookmarks in one place
            </Heading>
            <Heading size="md">
              Save web pages instantly. Find them again when you need them.
            </Heading>
            <Box>
              <CTA />
            </Box>
          </Stack>
        </Flex>

        <Box
          backgroundImage={bgImages}
          backgroundRepeat="no-repeat"
          backgroundPosition={'left'}
          backgroundSize={'1000px'}
          pos={'absolute'}
          top="0"
          bottom={'0px'}
          height="100%"
          width="100%"
          left="-400px"
          zIndex={2}
          display={['none', 'none', 'none', 'initial']}
        ></Box>
        <Box
          backgroundImage={bgImagesRight}
          backgroundRepeat="no-repeat"
          backgroundAttachment={'scroll'}
          backgroundColor={'transparent'}
          backgroundPosition={['left', 'left', 'center']}
          backgroundSize={['150%', '150%', '1000px']}
          pos={'absolute'}
          top="0"
          bottom={'0'}
          height="100%"
          width="100%"
          right={['-60vw', '-60vw', '-60vw', '-50vw']}
          zIndex={2}
        ></Box>
      </Flex>
      {/** Sections */}
      <Stack
        gap={14}
        bg="white"
        display="flex"
        pt={10}
        pb={14}
        alignItems={['flex-start', 'flex-start', 'center']}
        flexDir="column"
        overflow="hidden"
      >
        <Stack alignItems={'center'} alignSelf={'center'}>
          <MobileButtons />
        </Stack>
        <Reviews />

        <Fade triggerOnce={true} width="full">
          <Section
            left={false}
            header="Instantly save any page or all your tabs"
            subheader={
              <>
                With our{' '}
                <Link
                  variant="npAccent"
                  isExternal
                  href="/guides/using-the-extensions"
                >
                  browser extension
                </Link>
                , just click the Niphtio icon to save any web page instantly.
                Or, save all your tabs at once.
              </>
            }
            mp4="/landing/one_click_save.mp4"
            poster="/landing/sharing.jpg"
            video={true}
          ></Section>
        </Fade>

        <Fade triggerOnce={true} width="full">
          <Section
            left={false}
            header="Create collections. Share them with others"
            subheader={
              <>
                Curate collections of recipes, research, inspo, or anything you
                want. Keep them private, or share them with a single link.
              </>
            }
            image="/landing/sharing.jpg"
            video={false}
          ></Section>
        </Fade>

        <Fade triggerOnce={true} width="full">
          <Section
            left={false}
            header="Jot down notes or key takeaways"
            subheader="No more forgetting why you saved something. Add a note to any bookmark to remind yourself what it’s for or why it’s important to you."
            mp4="/landing/notes.mp4"
            poster="/landing/sharing.jpg"
            video={true}
          ></Section>
        </Fade>

        <Fade triggerOnce={true} width="full">
          <Section
            left={false}
            header="Find your bookmarks again with search"
            subheader="We know you keep losing the bookmarks you saved a while ago. Our built-in search will find the exact bookmark you need, when you need it."
            description=""
            image="/landing/search.jpg"
            video={false}
          ></Section>
        </Fade>

        <LandingStack></LandingStack>

        <Stack alignItems="center" w="full" gap={8}>
          <Stack gap={1} alignItems="center">
            <ReactIcon boxSize="44px" as={MdOutlineBookmarkBorder}></ReactIcon>

            <Heading fontSize="4xl" px={6} py={0}>
              Stop losing bookmarks. Make them work for you
            </Heading>
          </Stack>
          <Fade triggerOnce={true}>
            <InfoBanner />
          </Fade>
        </Stack>

        <Center margin="auto">
          <CTA />
        </Center>
      </Stack>
      {/** Bottom hero */}
      <Fade triggerOnce={true}>
        <BetaCtaRow onSignUpClick={handleFooterButtonClick} />
      </Fade>
      {/** Footer */}
      <Footer />
    </>
  );
};
